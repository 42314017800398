import { Container } from 'unstated';

class MainContainer extends Container {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      activeTab: 'about',
      calendar: null,
      userName: localStorage.getItem('userName'),
      userMailAddress: localStorage.getItem('userMailAddress')
    };

    this.setUser = this.setUser.bind(this);
  }

  setUser(userName, userMailAddress)
  {
    localStorage.setItem('userName', userName);
    localStorage.setItem('userMailAddress', userMailAddress);

    this.setState({userName: userName, userMailAddress: userMailAddress});
  }
}

export default MainContainer;

