import React from 'react';
import { Provider, Subscribe } from 'unstated';
import MainFrame from './Components/MainFrame'
import MainContainer from './containers/MainContainer'
import './App.css';

function App() {
  return (
    <div className="App">
      <Provider>
        <Subscribe to={[MainContainer]}>
          {container => (
            <MainFrame container={container} />
          )}
        </Subscribe>
      </Provider>
    </div>
  );
}

export default App;
