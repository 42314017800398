import React from 'react';
import { Form, Input, Button, Modal, Spin, Typography } from 'antd';
import { Reveal } from 'react-genie';
import { Layout } from 'antd';

const { Content } = Layout;
const { Title } = Typography;

// https://docs.google.com/spreadsheets/d/1XNUYRzx_SqxRwpoWZJnPwoNReD1e_ZET3TLr1eNSgWE/
const gasUrl = "https://script.google.com/macros/s/AKfycbwefAb8WU-61pyDAXjcD-SH1QN8TAwtRO7tYJuvaEHUbQ_AJ-Y/exec";
// https://script.google.com/home?hl=ja
// 1) 実行 > 関数を実行 > addData で OAUTH 認証を済ましておく
// 2) 公開 > Webアプリケーションとして導入
// 3) Product version: New
//    Excecute the app as: Me (mail-address)
//    Who has access to the app: Anyone, even anonymous

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
};

class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.onSendSuccess = this.onSendSuccess.bind(this);
        this.onSendError = this.onSendError.bind(this);
    }

    onCloseDialog() {
        console.log("onCloseDialog");
    }

    onSendSuccess() {
        console.log("onSendSuccess");

        this.setState({ loading: false });

        Modal.info({
            title: 'お知らせ',
            content: (
                <div>
                    <p>お問い合わせを送信しました</p>
                </div>
            ),
            onOk: this.onCloseDialog,
        });
    }

    onSendError(error) {
        console.log("onSendError");

        this.setState({ loading: false });

        Modal.error({
            title: '送信エラー',
            content: 'お問い合わせの送信に失敗しました',
        });
    }

    onFinish(values) {
        this.setState({ loading: true });

        const body = Object.keys(values)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(values[key]))
            .join("&");

        console.log('onFinish:', body);

        this.props.container.setUser(values['name'], values['mailAddress']);

        fetch(gasUrl, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: body
        })
            .then(() => {
                console.log(this);
                this.onSendSuccess();
            })
            .catch((error) => {
                console.log(this);
                this.onSendError(error);
            });
    }

    onFinishFailed(errorInfo) {
        console.log('onFinishFailed:', errorInfo);

        this.setState({ loading: false });
    }

    render() {
        const { container } = this.props;
        const { userName, userMailAddress } = container.state;

        return (
            <React.Fragment>
                <Content style={{
                    padding: 24,
                    marginTop: 64
                }}>
                    <Reveal>
                        <Spin spinning={this.state.loading}>
                            <Title level={2}>お問い合わせ</Title>
                            <div style={{
                                margin: 'auto',
                                boxSizing: 'border-box',
                                maxWidth: '40rem',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                            }}>
                                <div style={{ textAlign: 'left' }}>
                                    <Typography>"お名前"、"メールアドレス"、"お問い合わせ内容"をご記入の上[送信]ボタンを押してください。</Typography>
                                    <Typography>メールにて返信いたしますが、返信が遅れることもございます。あらかじめご了承ください。</Typography>
                                </div>
                            </div>
                            <Form
                                {...layout}
                                name="contact"
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                                style={{ marginTop: 20 }}
                                initialValues={{
                                    name: userName,
                                    mailAddress: userMailAddress
                                }}
                            >
                                <Form.Item
                                    name="name"
                                    label="お名前"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'お名前を入力してください'
                                        }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="mailAddress"
                                    label="メールアドレス"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: '正しいメールアドレスを入力してください',
                                        },
                                        {
                                            required: true,
                                            message: 'メールアドレスを入力してください',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="message"
                                    label="お問い合わせ内容"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'お問い合わせ内容を入力してください'
                                        }]}
                                >
                                    <Input.TextArea autoSize />
                                </Form.Item>
                                <Button type="primary" htmlType="submit">
                                    送信
                                </Button>
                            </Form>
                        </Spin>
                    </Reveal>
                </Content>
            </React.Fragment>
        );
    }
}

export default Contact;
