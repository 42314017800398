import React from 'react';
import { Button } from 'antd';
import { TwitterOutlined, InstagramOutlined } from '@ant-design/icons';

class ProfileCardOverlay extends React.Component {
    render() {
        const { imageUrl } = this.props;

        const styleCard = {
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            borderRadius: '0.25rem'
        };

        const styleOverlay = {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            filter: 'blur(20px) brightness(0.75)',
            backfaceVisibility: 'hidden',
            perspective: 1000,
            transform: 'translate3d(0,0,0) translateZ(0)',
            willChange: 'transform',
            backgroundImage: `url(${imageUrl})`
        };

        return (
            <div
                style={styleCard}
            >
                <div
                    style={styleOverlay}
                />
            </div>
        );
    }
}

class ProfileCardContent extends React.Component {
    render() {
        const { imageUrl, title, buttons } = this.props;

        const styleContent = {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            zIndex: 10
        };

        const styleThumbnail = {
            display: 'flex',
            height: '50%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem 0 0 0',
            flex: '1 1 auto'
        };

        const styleImage = {
            width: 'auto',
            height: '100%',
            objectFit: 'cover',
            boxShadow: '0 16px 24px 2px rgba(#2c3e50,0.24), 0 6px 30px 5px rgba(#2c3e50,0.22), 0 8px 10px -5px rgba(#2c3e50,0.4)'
        };

        const styleText = {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            width: '100%',
            padding: 10,
            borderTop: '1px solid rgba(#ecf0f1, 0.2)',
            color: '#ecf0f1'
        }

        return (
            <div
                style={styleContent}
            >
                <div style={styleThumbnail}>
                    <img style={styleImage} src={imageUrl} alt={title} />
                </div>

                <div>
                    <div style={styleText}>
                        {title}
                    </div>
                    <div>
                        {
                            buttons.map((tag, i) => {
                                return (
                                    <span key={i}>
                                        {typeof tag === "function" ? tag() : tag}
                                    </span>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class ProfileCard extends React.Component {
    constructor(props) {
        super(props);

        this.onClickTwitter = this.onClickTwitter.bind(this);
        this.onClickInstagram = this.onClickInstagram.bind(this);
    }

    onClickTwitter() {
        window.open(this.props.twitterUrl, "_blank");
    }

    onClickInstagram() {
        window.open(this.props.instagramUrl, "_blank");
    }

    render() {
        const { title, imageUrl, width, height } = this.props;

        const styleCard = {
            position: 'relative',
            maxWidth: '100%',
            display: 'flex',
            flexFlow: 'column',
            borderRadius: '0.25rem',
            margin: 5,
            userSelect: 'none',
            width: width,
            height: height,
            backgroundColor: '#2c3e50',
            transition: '0.5s',
        };

        return (
            <React.Fragment>
                <div style={styleCard}>
                    <ProfileCardOverlay imageUrl={imageUrl} />
                    <ProfileCardContent
                        imageUrl={imageUrl}
                        title={title}
                        buttons={[
                            <Button type="primary"
                                icon={<TwitterOutlined />}
                                size={100}
                                style={{ margin: 10 }}
                                onClick={this.onClickTwitter} />,
                            <Button type="primary"
                                icon={<InstagramOutlined />}
                                size={100} style={{ margin: 10 }}
                                onClick={this.onClickInstagram} />
                        ]}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default ProfileCard;
