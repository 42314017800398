import React from 'react';

// https://console.developers.google.com/apis/dashboard
// 1) 認証情報
// 2) "OAuth 2.0 クライアント ID" からプロジェクト選択
// 3) "URI" と "承認済みのリダイレクト URI" にURL追加
// 4) [保存]

class FetchSchedule extends React.Component {
    constructor(props) {
        super(props);

        this.initClient = this.initClient.bind(this);
    }

    componentDidMount() {
        this.initClient();
    }

    initClient() {
        const CALENDAR_ID= 'atmkblog@gmail.com';
        const CLIENT_ID = '992378260187-ff11f16g8740o7p8gd5drgd8194qea3d.apps.googleusercontent.com';
        const API_KEY = 'AIzaSyB4zCLq8s4e4BvDJngLadikWPKlY1OsjVk';
        const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
        const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

        window.gapi.load('client:auth2', () => {
            // console.log(window.gapi.client);

            window.gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES
            }).then(() => {
                window.gapi.client.calendar.events.list({
                    'calendarId': CALENDAR_ID,
                    'timeMin': (new Date()).toISOString(),
                    'showDeleted': false,
                    'singleEvents': true,
                    'orderBy': 'startTime'
                }).then((response) => {
                    const events = response.result.items;
                    // console.log(events);
                    this.props.container.setState({calendar: events});
                });
            }, (error) => {
                console.log(error);
            });
        });
    }

    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

export default FetchSchedule;
