import React from 'react';
import { Typography, Layout } from 'antd';
import { Reveal } from 'react-genie';

const { Content } = Layout;

class About extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Reveal>
                    <Content style={{
                            paddingTop: 32,
                        }}>
                        <div>
                            <img src="topimage.jpg" alt="top" style={{ width: '100%', height: '100%' }} />
                        </div>
                    </Content>
                </Reveal>
                <Reveal>
                    <Content
                        style={{
                            paddingTop: 16,
                            paddingLeft: 36,
                            paddingRight: 36,
                            paddingBottom: 16
                        }}
                    >
                        <Typography>2017年7月12日 結成</Typography>
                        <Typography>2018年12月20日 解散</Typography>
                    </Content>
                </Reveal>
                <Reveal>
                    <Content
                        style={{
                            paddingTop: 16,
                            paddingLeft: 36,
                            paddingRight: 36,
                            paddingBottom: 16
                        }}
                    >
                        <div
                            className="video"
                            style={{
                                position: "relative",
                                paddingBottom: "56.25%" /* 16:9 */,
                                paddingTop: 25,
                                height: 0
                            }}
                        >
                            <iframe
                                title="YouTube"
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%"
                                }}
                                src={`https://www.youtube.com/embed/1o3RUA8hdVI`}
                                frameBorder="0"
                            />
                        </div>
                    </Content>
                </Reveal>
            </React.Fragment >
        );
    }
}

export default About;
