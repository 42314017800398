import React from 'react';
import { Layout } from 'antd';
import HeaderContent from './HeaderContent'

const { Header } = Layout;

class MainHeader extends React.Component {
    render() {
        const { container } = this.props;

        const styleHeader = {
            position: 'fixed',
            width: '100%',
            paddingLeft: 20,
            zIndex: 100
        }

        return (
            <React.Fragment>
                <Header style={styleHeader}>
                    <HeaderContent container={container} />
                </Header>
            </React.Fragment>
        );
    }
}

export default MainHeader;
