import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import ReservationDialog from './ReservationDialog';

class CollapsibleCard extends React.Component {
    static propTypes = {
        isOpened: PropTypes.bool
    };

    static defaultProps = {
        isOpened: false
    };

    constructor(props) {
        super(props);
        const { isOpened } = this.props;

        this.state = {
            isOpened: isOpened,
            showDialog: false
        };

        this.onClickRight = this.onClickRight.bind(this);
        this.onClickDown = this.onClickDown.bind(this);
        this.onClickButton = this.onClickButton.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
    }

    onClickRight() {
        this.setState({ isOpened: true });
    }

    onClickDown() {
        this.setState({ isOpened: false });
    }

    onClickButton() {
        this.setState({ showDialog: true });
    }

    hideDialog() {
        this.setState({ showDialog: false });
    }

    render() {
        const { container, when, summary, description } = this.props;
        const { isOpened, showDialog } = this.state;

        const moment = require("moment");
        moment.lang('ja', {
            weekdays: ["日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日"],
            weekdaysShort: ["日","月","火","水","木","金","土"],
        });
        const eventDate = moment(when).format("YYYY/MM/DD (ddd)");

        const onClick = (isOpened) ? this.onClickDown : this.onClickRight;
        const icon = (isOpened) ? (<DownOutlined />) : (<RightOutlined />);

        const summaryComponent = (
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}>
                <div onClick={onClick} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', marginRight: 10 }}>
                        {icon}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
                        <div style={{ fontSize: 12, textAlign: 'left' }}>{eventDate}</div>
                        <div style={{ fontSize: 14, textAlign: 'left' }}>{summary}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button type="primary" onClick={this.onClickButton}>予約</Button>
                </div>
            </div>
        );

        let text = (<React.Fragment></React.Fragment>);
        if (isOpened) {
            const ReactMarkdown = require('react-markdown/with-html')
            text = (
                <React.Fragment>
                    <div style={{ height: 1, border: 'dashed 1px #d3d3d3' }}></div>
                    <div style={{ fontSize: 12, textAlign: 'left', margin: 10 }}>
                        <ReactMarkdown
                            source={description}
                            escapeHtml={false}
                        />
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 8,
                    borderRadius: 5,
                    backgroundColor: '#FFFFFF',
                    filter: 'drop-shadow(5px 5px 5px rgba(0,0,0,0.6))'
                }}>
                    {summaryComponent}
                    {text}
                </div>
                <ReservationDialog container={container} when={when} summary={summary} showDialog={showDialog} hideDialog={this.hideDialog} />
            </React.Fragment>
        );
    }
}

export default CollapsibleCard;

