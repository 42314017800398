import React from 'react';
import { RevealGlobalStyles } from 'react-genie'
import { Layout } from 'antd';
import MainHeader from './MainHeader'
import MainView from './MainView'
import MainFooter from './MainFooter'
import FetchSchedule from './FetchSchedule'

class MainFrame extends React.Component {
    componentDidMount() {
        const MobileDetect = require('mobile-detect');
        const md = new MobileDetect(window.navigator.userAgent);
        if(md.mobile()){
            this.props.container.setState({isMobile: true});
        }
    }

    render() {
        const { container } = this.props;

        return (
            <React.Fragment>
                <RevealGlobalStyles />
                <FetchSchedule container={container} />
                <Layout>
                    <MainHeader container={container} />
                    <MainView container={container} />
                    <MainFooter container={container} />
                </Layout>
            </React.Fragment>
        );
    }
}

export default MainFrame;

