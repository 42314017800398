import React from 'react';
import { Typography } from 'antd';

class FooterContent extends React.Component {
    render() {
        const styleDiv = {
            backgroundColor: '#000000',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
        };

        const styleText = {
            color: '#ffffff',   
            width: '100%',
            textAlign: 'center' 
        };

        return (
            <React.Fragment>
                <div style={styleDiv}>
                    <Typography style={styleText}>ファンが気まぐれで作ったランディングページです</Typography>
                </div>
            </React.Fragment>
        );
    }
}

export default FooterContent;