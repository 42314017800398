import React from 'react';
import { Reveal } from 'react-genie';
import { Layout } from 'antd';
import CollapsibleCard from './CollapsibleCard';

const { Content } = Layout;

class Schedule extends React.Component {

    render() {
        const { container } = this.props;
        const calendar = container.state.calendar;
        console.log(calendar);

        if (calendar == null) {
            return <React.Fragment></React.Fragment>;
        }

        let events = [];
        if (calendar.length > 0) {
            for (let i = 0; i < calendar.length; i++) {
                const event = calendar[i];
                // console.log(event);
                const summary = event.summary;
                const description = event.description;
                let when = event.start.dateTime;
                if (!when) {
                    when = event.start.date;
                }
                const tag = (
                    <React.Fragment key={i}>
                        <Reveal>
                            <CollapsibleCard container={container} when={when} summary={summary} description={description} />
                        </Reveal>
                    </React.Fragment>);
                events.push(tag);
            }
        } else {
        }

        return (
            <React.Fragment>
                <Content style={{
                    padding: 24,
                    minHeight: 280,
                    marginTop: 64
                }}>
                    {events}
                </Content>
            </React.Fragment>
        );
    }
}

export default Schedule;
