import React from 'react';
import { Layout } from 'antd';
import FooterContent from './FooterContent'

const { Footer } = Layout;

class MainFooter extends React.Component {
    render() {
        const { container } = this.props;

        const height = 40;

        const styleSpace = {
            height: height,
        }

        const styleFooter = {
            position: 'fixed',
            width: '100%',
            height: height,
            bottom: 0,
            padding: 0,
            zIndex: 100,
        };

        return (
            <React.Fragment>
                <div style={styleSpace} />
                <Footer theme="dark" style={styleFooter}>
                    <FooterContent container={container} />
                </Footer>
            </React.Fragment>
        );
    }
}

export default MainFooter;
