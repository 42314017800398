import React from 'react';
import { Layout, Row, Col } from 'antd';
import { Reveal } from 'react-genie';
import ProfileCard from './ProfileCard';

const { Content } = Layout;

class Profile extends React.Component {
    render() {
        const width = 240;
        const height = 320;

        return (
            <React.Fragment>
                <div id="pos-profile" />
                <Content style={{
                    padding: 24,
                    minHeight: 280,
                    marginTop: 64
                }}>
                    <Row>
                        <Col>
                            <Reveal>
                                <ProfileCard
                                    title="岩﨑ひかり"
                                    imageUrl="profile01.jpg"
                                    width={width}
                                    height={height} />
                            </Reveal>
                        </Col>
                        <Col>
                            <Reveal>
                                <ProfileCard
                                    title="嶋木すい"
                                    imageUrl="profile02.jpg"
                                    width={width}
                                    height={height} />
                            </Reveal>
                        </Col>
                        <Col>
                            <Reveal>
                                <ProfileCard
                                    title="蒼井李々子"
                                    imageUrl="profile03.jpg"
                                    width={width}
                                    height={height} />
                            </Reveal>
                        </Col>
                        <Col>
                            <Reveal>
                                <ProfileCard
                                    title="鳴瀬華恋"
                                    imageUrl="profile04.jpg"
                                    width={width}
                                    height={height} />
                            </Reveal>
                        </Col>
                    </Row>
                </Content>
            </React.Fragment>
        );
    }
}

export default Profile;
