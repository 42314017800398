import React from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

class HeaderContent extends React.Component {
    constructor(props) {
        super(props);

        this.onClickAbout = this.onClickAbout.bind(this);
        this.onClickProfile = this.onClickProfile.bind(this);
        this.onClickSchedule = this.onClickSchedule.bind(this);
        this.onClickContact = this.onClickContact.bind(this);
    }

    onClickAbout() {
        this.props.container.setState({ activeTab: 'about' });
    }

    onClickProfile() {
        this.props.container.setState({ activeTab: 'profile' });
    }

    onClickSchedule() {
        this.props.container.setState({ activeTab: 'schedule' });
    }

    onClickContact() {
        this.props.container.setState({ activeTab: 'contact' });
    }

    render() {
        const { container } = this.props;

        const menus = [
            ['About', this.onClickAbout],
            ['Profile', this.onClickProfile],
            ['Schedule', this.onClickSchedule],
            ['Contact', this.onClickContact],
        ]

        let menuComponent;
        if (container.state.isMobile) {
            let menusItems = [];
            for (const i in menus) {
                menusItems.push(<Menu.Item key={i} onClick={menus[i][1]} style={{ margin: 8 }}>{menus[i][0]}</Menu.Item>);
            }

            const child = (<Menu theme="dark">
                {menusItems}
            </Menu>);

            menuComponent = (
                <div style={{ display: 'flex' }}>
                    <div>
                        <Dropdown
                            overlay={child}
                            placement="bottomLeft">
                            <Button>
                                <MenuOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                    <div style={{ fontSize: 18, width: '100%', textAlign: 'center', color: '#FFFFFF' }}>
                        かぷりす
                    </div>
                </div>);
        } else {
            let menusItems = [];
            for (const i in menus) {
                menusItems.push(<Menu.Item key={i} onClick={menus[i][1]}>{menus[i][0]}</Menu.Item>);
            }

            menuComponent = (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ float: 'left', marginLeft: 20, fontSize: 18, color: '#FFFFFF' }}>
                        かぷりす
                    </div>
                    <div>
                        <Menu
                            defaultSelectedKeys={['0']}
                            mode="horizontal"
                            theme="dark"
                            style={{ float: 'right', lineHeight: '64px' }}
                        >
                            {menusItems}
                        </Menu>
                    </div>
                </div>);
        }

        return (
            <React.Fragment>
                {menuComponent}
            </React.Fragment>
        );
    }
}

export default HeaderContent;
