import React from 'react';
import { Drawer, Modal, Form, Input, InputNumber, Button, Spin, Typography } from 'antd';

const { Title } = Typography;

// https://docs.google.com/spreadsheets/d/1Wj9TujvkwRU_FiQFPmy3D66G9rm37d6qMxGvuSSRQGM/
const gasUrl = "https://script.google.com/macros/s/AKfycbyRbFY9lVe36a9se-PN7D3KxXN0J1AmJSVbpMTVH2YOn2oL9Y0b/exec";
// https://script.google.com/home?hl=ja
// 1) 実行 > 関数を実行 > addData で OAUTH 認証を済ましておく
// 2) 公開 > Webアプリケーションとして導入
// 3) Product version: New
//    Excecute the app as: Me (mail-address)
//    Who has access to the app: Anyone, even anonymous

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
};

class ReservationDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.onCancel = this.onCancel.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.onSendSuccess = this.onSendSuccess.bind(this);
        this.onSendError = this.onSendError.bind(this);
    }

    onCancel() {
        this.props.hideDialog();
    }

    onSendSuccess() {
        console.log("onSendSuccess");

        this.setState({ loading: false });

        Modal.info({
            title: 'お知らせ',
            content: (
                <div>
                    <p>イベント予約を送信しました</p>
                </div>
            ),
            onOk: this.onCloseDialog,
        });

        this.props.hideDialog();
    }

    onSendError(error) {
        console.log("onSendError");

        this.setState({ loading: false });

        Modal.error({
            title: '送信エラー',
            content: 'イベント予約の送信に失敗しました',
        });
    }

    onFinish(values) {
        this.setState({ loading: true });

        const body = Object.keys(values)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(values[key]))
            .join("&");

        console.log('onFinish:', body);

        this.props.container.setUser(values['name'], values['mailAddress']);

        fetch(gasUrl, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: body
        })
            .then(() => {
                console.log(this);
                this.onSendSuccess();
            })
            .catch((error) => {
                console.log(this);
                this.onSendError(error);
            });
    }

    onFinishFailed(errorInfo) {
        console.log('onFinishFailed:', errorInfo);

        this.setState({ loading: false });
    }

    render() {
        const { container, showDialog, when, summary } = this.props;
        const { userName, userMailAddress } = container.state;

        const minSheets = 1;
        const maxSheets = 10;

        const moment = require("moment");
        moment.lang('ja', {
            weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
            weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
        });
        const eventDate = moment(when).format("YYYY/MM/DD (ddd)");

        return (
            <React.Fragment>
                <Drawer
                    width="100%"
                    placement="right"
                    closable={false}
                    onClose={this.onCancel}
                    visible={showDialog}
                    getContainer={false}
                    style={{ position: 'fixed', zIndex: 200 }}>
                    <Spin spinning={this.state.loading}>
                        <Title level={2}>イベント予約</Title>
                        <Typography>※実際に予約されませんのでご注意ください！</Typography>
                        <Form
                            {...layout}
                            name="contact"
                            initialValues={{
                                sheets: 1,
                                eventDate: eventDate,
                                eventName: summary,
                                name: userName,
                                mailAddress: userMailAddress
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            style={{ marginTop: 40 }}
                        >
                            <Form.Item
                                name="eventDate"
                                label="日付"
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                name="eventName"
                                label="イベント名"
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                name="name"
                                label="お名前"
                                rules={[
                                    {
                                        required: true,
                                        message: 'お名前を入力してください'
                                    }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="mailAddress"
                                label="メールアドレス"
                                rules={[
                                    {
                                        type: 'email',
                                        message: '正しいメールアドレスを入力してください',
                                    },
                                    {
                                        required: true,
                                        message: 'メールアドレスを入力してください',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="sheets"
                                label="枚数"
                                rules={[{
                                    type: 'number',
                                    min: minSheets,
                                    max: maxSheets,
                                    message: `${minSheets} ～ ${maxSheets} を入力してください`,
                                    required: true
                                }]}>
                                <InputNumber />
                            </Form.Item>

                            <div style={{
                                margin: 'auto',
                                boxSizing: 'border-box',
                                width: '12rem',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <Button onClick={this.onCancel}>
                                    取り消し
                                    </Button>
                                <Button type="primary" htmlType="submit">
                                    送信
                                    </Button>
                            </div>
                            <div style={{height: 32}} />
                        </Form>
                    </Spin>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default ReservationDialog;
