import React from 'react';
import About from './About'
import Profile from './Profile'
import Schedule from './Schedule'
import Contact from './Contact'

class MainView extends React.Component {
    render() {
        const { container } = this.props;

        let content;
        switch (container.state.activeTab) {
            case 'profile':
                content = <Profile container={container} />;
                break;
            case 'schedule':
                content = <Schedule container={container} />;
                break;
            case 'contact':
                content = <Contact container={container} />;
                break;
            default:
                content = <About container={container} />;
                break;
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
}

export default MainView;
